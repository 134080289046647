html,
body,
p,
span,
h1,
a,
select,
option {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
}

html,
body,
#root {
  height: 100%;
}

body {
  background-color: #f7fafc;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

button {
  background-color: transparent;
  border: none;
}
